<template>
  <div style="padding: 20px;">
    <v-row justify="space-between" align="center">
      <v-btn depressed color="primary" to="loans/register">
        <v-icon small class="pull-right">mdi-plus</v-icon> Add Loan
      </v-btn>
      <h1 class="mb-3">Loans</h1>
      <div>
        <v-btn color="primary" plain v-on:click="init()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
    </v-row>
    
    <v-row v-if="isLoading" justify="center" no-gutters class="my-12">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-row>

    <v-row v-if="!loans.length && !isLoading" justify="center" no-gutters class="my-12">
      <v-alert dense type="info">
        No loans registered.
      </v-alert>
    </v-row>

    <v-row v-else-if="loans.length && !isLoading" style="min-height: 60vh;">
      <v-col sm="6" md="4" lg="4" v-for="loan in loans" :key="loan.id">
        <v-card :disabled="loan.situacao == 'DEACTIVADED'" min-width="200">
          <v-card-title>
            # {{ loan.stockId }}
          </v-card-title>
          <v-card-subtitle>
            <small>({{loan.nomeCarro}})</small>
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :to="`/loans/${loan.id}`" depressed v-if="loan.situacao != 'DEACTIVADED'">
              View loan
            </v-btn>
            <v-btn depressed color="error" v-on:click="inactive(loan.id)" v-if="loan.situacao != 'DEACTIVADED' && loan.situacao != 'PAID'">
              Inactive
            </v-btn>
            <v-btn depressed color="error" v-if="loan.situacao == 'DEACTIVADED'">
              DEACTIVADED
            </v-btn>
            <v-btn depressed color="success" v-if="loan.situacao == 'PAID' && !loan.paidWithPayOff">
              ALL PAID
            </v-btn>
            <v-btn depressed color="primary" v-if="loan.situacao == 'PAID' && loan.paidWithPayOff">
              PAID WITH PAY-OFF
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" style="margin-top: 50px;">
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="pageLength"
          @input="usePagination(page)"
          :total-visible="7"
        ></v-pagination>
      </div>
    </v-row>
  </div>
</template>

<script>

import LoanService from '../services/LoanService';
import CreateToast from '../../../utils/createToast';

export default {
  name: "Home",

  data: () => ({
    loans: [],
    isLoading: false,
    page: 0,
    pageLength: 0
  }),

  async created() {
    this.init()
  },

  methods: {
    async init() {
      this.isLoading = true;
      this.loans = [];
      LoanService.findAll().then(response => {
        this.loans = response.data.content;
        this.page = response.data.number + 1;
        this.pageLength = response.data.totalPages;
      }).catch(err => {
        CreateToast.createToastFailed('An error ocurred! Please try again!');
      }).finally(() => {
        this.isLoading = false;
      });
    },

    inactive(idLoan) {
      this.$confirm(`This action will deactivate this register (this action cannot be undone). Do you wish to continue?`).then(response => {
        if (response) {
          this.isLoading = true;
          LoanService.inactive(idLoan).then(() => {
            CreateToast.createToastSuccess('Operation success.');
            this.init();
          }).catch(err => {
            CreateToast.createToastFailed('An error ocurred! Please try again!');
          });
        }
      });
    },

    usePagination(page) {
      this.isLoading = true;
      LoanService.findAll(page - 1).then(response => {
        this.loans = response.data.content;
        this.page = response.data.number + 1;
      }).catch(err => {
        CreateToast.createToastFailed('An error ocurred! Please try again!');
      }).finally(() => {
        this.isLoading = false;
      })
    }
  }
};
</script>
